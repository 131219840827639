import gsap from 'gsap';
import { useEffect, useState, useRef } from 'react';
import { useGenerationStore } from 'src/hooks/useGenerationStore';
import Observer from 'gsap/Observer';
import CustomEase from 'gsap/CustomEase';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Observer, CustomEase, ScrollTrigger);

const useObserver = () => {
	const {
		setActiveSlide,
		setIsAnimating,
		timelineSlide1,
		timelineSlide2,
		setProgress,
		timelineSlide3,
		timelineSlide4,
		timelineSlide5,
		timelineSlide6,
		timelineSlide7,
		timelineSlide8,
		tlInfiniteBg,
		isChangingSlide,
		setIsChangingSlide,
	} = useGenerationStore();
	const animating = useRef(false);
	const activeSlideRef = useRef(0);
	const lottieRef = useRef<any>(null);
	const animatingBg = useRef(false);
	const tlInfiniteBgArray = useRef<any>(null);

	useEffect(() => {
		const unsub = useGenerationStore.subscribe((state, prevState) => {
			activeSlideRef.current = state.activeSlide;
			// setTlSlide2(state.timelineSlide2);
			lottieRef.current = state.lottie;
			// console.log(
			// 	state.isAnimating,
			// 	'state',
			// 	prevState.isAnimating,
			// 	'prevState'
			// );
			animating.current = state.isAnimating;
			tlInfiniteBgArray.current = state.tlInfiniteBgArray;
		});
		return () => {
			unsub();
		};
	}, []);

	useEffect(() => {
		// isMobile

		const gotoSectionUp = (index: number, direction: number) => {
			const allSlidesElement = document.querySelectorAll('.slide');
			if (index < 0 || index > 9) return;
			// add class active to the current slide

			//    clear active class first
			allSlidesElement.forEach((slide, i) => {
				slide.classList.remove('active');
			});

			document
				.querySelector(`[tabindex="${index}"]`)
				?.classList.add('active');
			if (index === 2 && direction === 1) {
				setProgress(2);
				tlInfiniteBg
					.timeScale(2)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						gsap.to(tlInfiniteBgArray.current.element, {
							opacity: 0,
							duration: 1,
						});
					});
				timelineSlide1
					.timeScale(2)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						timelineSlide2.tlImage.timeScale(1).play();
						timelineSlide2.tl
							.timeScale(1)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
					});
			}

			if (index === 3 && direction === 1) {
				setProgress(3);
				timelineSlide2.tlImage.timeScale(1.5).reverse();
				timelineSlide2.tl
					.timeScale(1.5)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						if (lottieRef.current) {
							lottieRef.current.setSpeed(1.35);
							lottieRef.current.play();
						}
						timelineSlide3
							.timeScale(1)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
					});
			}

			if (index === 4 && direction === 1) {
				setProgress(4);
				timelineSlide3
					.timeScale(3)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						gsap.set(timelineSlide4.elementImage, {
							alpha: 1,
						});

						timelineSlide4.tl
							.timeScale(1.5)
							.play()
							.eventCallback('onComplete', () => {
								if (lottieRef.current) {
									lottieRef.current.stop();
								}
								animating.current = false;
							});

						timelineSlide4.tlImage.timeScale(1.5).play();
					});
			}

			if (index === 5 && direction === 1) {
				setProgress(5);
				timelineSlide4.tl.timeScale(3).reverse();
				// timelineSlide4.tlImage.timeScale(3).reverse().eventCallback('onReverseComplete', () => {
				const exit = gsap
					.timeline({ paused: true })
					.to(timelineSlide4.elementImage, { alpha: 0, duration: 1 });

				timelineSlide5.tlImage.timeScale(10).reverse();

				exit.timeScale(1)
					.play()
					.eventCallback('onComplete', () => {
						timelineSlide5.tl
							.timeScale(1.5)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						gsap.set(timelineSlide5.elementImage, {
							alpha: 1,
						});

						timelineSlide5.tlImage.timeScale(1.5).play();
					});
			}

			if (index === 6 && direction === 1) {
				setProgress(6);
				timelineSlide5.tl.timeScale(3).reverse();

				const exit = gsap
					.timeline({ paused: true })
					.to(timelineSlide5.elementImage, { alpha: 0, duration: 1 });

				timelineSlide6.tlImage.timeScale(10).reverse();

				// timelineSlide5.tlImage.timeScale(3).reverse().eventCallback('onReverseComplete', () => {
				exit.timeScale(1)
					.play()
					.eventCallback('onComplete', () => {
						timelineSlide6.tl
							.timeScale(1.5)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						gsap.set(timelineSlide6.elementImage, {
							alpha: 1,
						});

						timelineSlide6.tlImage
							.timeScale(1)
							.timeScale(1.5)
							.play();
					});
			}

			if (index === 7 && direction === 1) {
				setProgress(7);
				timelineSlide6.tl.timeScale(3).reverse();
				// timelineSlide6.tlImage.timeScale(2).reverse().eventCallback('onReverseComplete', () => {
				const exit = gsap
					.timeline({ paused: true })
					.to(timelineSlide6.elementImage, { alpha: 0, duration: 1 });

				exit.timeScale(1)
					.play()
					.eventCallback('onComplete', () => {
						timelineSlide7.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});

						timelineSlide7.tlImage.timeScale(1).play();
					});
			}

			if (index === 8 && direction === 1) {
				setProgress(8);
				timelineSlide7.tl
					.timeScale(2)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						timelineSlide7.tlImageZ.timeScale(1).play();
						document.documentElement.setAttribute(
							'data-theme',
							'dark'
						);
						timelineSlide8.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						timelineSlide8.tlImage.timeScale(1).play();
					});
			}

			setIsChangingSlide(false);
		};

		const gotoSectionDown = (index: number, direction: number) => {
			const allSlidesElement = document.querySelectorAll('.slide');
			if (index < 0 || index > 9) return;
			// add class active to the current slide
			allSlidesElement.forEach((slide, i) => {
				if (i === index - 1) {
					slide.classList.add('active');
				} else {
					slide.classList.remove('active');
				}
			});
			if (index === 1 && direction === -1) {
				setProgress(1);
				timelineSlide2.tlImage.timeScale(2).reverse();
				timelineSlide2.tl
					.timeScale(1.5)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						tlInfiniteBg.timeScale(1.5).play();
						gsap.to(tlInfiniteBgArray.current.element, {
							opacity: 1,
							duration: 1,
						});
						timelineSlide1
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
					});
			}

			if (index === 2 && direction === -1) {
				setProgress(2);
				timelineSlide3
					.timeScale(2)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						timelineSlide2.tl.timeScale(2).play();
						if (lottieRef.current) {
							lottieRef.current.stop();
						}

						timelineSlide2.tlImage
							.timeScale(1)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
					});
			}

			if (index === 3 && direction === -1) {
				setProgress(3);
				timelineSlide4.tl.timeScale(3).reverse();
				const exit = gsap
					.timeline({ paused: true })
					.to(timelineSlide4.elementImage, { alpha: 0, duration: 1 });
				exit.timeScale(1)
					.play()
					.eventCallback('onComplete', () => {
						if (lottieRef.current) {
							lottieRef.current.setSpeed(1.2);
							lottieRef.current.play();
						}
						timelineSlide3
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						// REVERSE THE IMAGE
						timelineSlide4.tlImage.timeScale(10).reverse();
					});
			}

			if (index === 4 && direction === -1) {
				setProgress(4);
				timelineSlide5.tl.timeScale(3).reverse();
				const exit = gsap
					.timeline({ paused: true })
					.to(timelineSlide5.elementImage, { alpha: 0, duration: 1 });
				// timelineSlide5.tlImage.timeScale(2).reverse().eventCallback('onReverseComplete', () => {
				timelineSlide4.tlImage.timeScale(10).reverse();

				exit.play().eventCallback('onComplete', () => {
					gsap.set(timelineSlide4.elementImage, {
						alpha: 1,
					});
					timelineSlide4.tl
						.timeScale(2)
						.play()
						.eventCallback('onComplete', () => {
							animating.current = false;
						});

					timelineSlide4.tlImage.timeScale(2).play();
				});
			}

			if (index === 5 && direction === -1) {
				setProgress(5);
				timelineSlide6.tl.timeScale(3).reverse();
				timelineSlide5.tlImage.timeScale(10).reverse();

				const exit = gsap
					.timeline({ paused: true })
					.to(timelineSlide6.elementImage, { alpha: 0, duration: 1 });

				// timelineSlide6.tlImage.timeScale(2).reverse().eventCallback('onReverseComplete', () => {
				exit.timeScale(1)
					.play()
					.eventCallback('onComplete', () => {
						gsap.set(timelineSlide5.elementImage, {
							alpha: 1,
						});
						timelineSlide5.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						timelineSlide5.tlImage.timeScale(2).play();
					});
			}

			if (index === 6 && direction === -1) {
				setProgress(6);
				timelineSlide7.tlImage.timeScale(2).reverse();

				timelineSlide6.tlImage.timeScale(10).reverse();
				timelineSlide7.tl
					.timeScale(2)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						gsap.set(timelineSlide6.elementImage, {
							alpha: 1,
						});

						timelineSlide6.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						timelineSlide6.tlImage.timeScale(2).play();
					});
			}

			if (index === 7 && direction === -1) {
				setProgress(7);
				timelineSlide8.tl.timeScale(3).reverse();
				timelineSlide8.tlImage
					.timeScale(1)
					.reverse()
					.eventCallback('onReverseComplete', () => {
						document.documentElement.setAttribute(
							'data-theme',
							'light'
						);
						timelineSlide7.tlImageZ.timeScale(2).reverse();
						timelineSlide7.tl
							.timeScale(2)
							.play()
							.eventCallback('onComplete', () => {
								animating.current = false;
							});
						timelineSlide7.tlImage.timeScale(2).play();
					});
			}
			setIsChangingSlide(false);
		};

		// OBSERVER
		if (activeSlideRef.current >= 1 && activeSlideRef.current < 9) {
			Observer.create({
				type: 'wheel,touch',
				onUp: (self) => {
					if (activeSlideRef.current === 0) {
						// handleScrollBg(self, -1)
					}
					if (
						!animating.current &&
						activeSlideRef.current < 8 &&
						activeSlideRef.current > 0
					) {
						animating.current = true;
						setIsAnimating(true);

						// const nextSlide = isMobile
						// 	? activeSlideRef.current - 1
						// 	: activeSlideRef.current + 1;
						const nextSlide = activeSlideRef.current + 1;
						setActiveSlide(nextSlide);
						setIsChangingSlide(true);

						gotoSectionUp(nextSlide, 1);
					}
				},
				onDown: (self) => {
					if (activeSlideRef.current === 0 && !animating.current) {
						// handleScrollBg(self, -1)
					}
					if (!animating.current && activeSlideRef.current > 1) {
						animating.current = true;
						setIsAnimating(true);
						setIsChangingSlide(true);
						const prevSlide = activeSlideRef.current - 1;
						setActiveSlide(prevSlide);

						gotoSectionDown(prevSlide, -1);
					}
				},
				wheelSpeed: -1,
				tolerance: 20,
				preventDefault: true,
				onPress: (self) => {
					// for IOS only, prevent default
					// because it will scroll the whole page
					// ScrollTrigger.isTouch && self.event.preventDefault();
				},
			});
		}

		// bind keyboard event
		const handleKeydown = (e: KeyboardEvent) => {
			if (
				e.key === 'ArrowUp' ||
				e.key === 'ArrowDown' ||
				e.key === 'ArrowLeft' ||
				e.key === `ArrowRight`
			) {
				if (animating.current) return;
				if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
					if (
						activeSlideRef.current > 0 &&
						activeSlideRef.current <= 8
					) {
						animating.current = true;
						setIsAnimating(true);
						const prevSlide = activeSlideRef.current - 1;
						setActiveSlide(prevSlide);
						gotoSectionDown(prevSlide, -1);
					}
				} else {
					if (
						activeSlideRef.current < 8 &&
						activeSlideRef.current > 0
					) {
						animating.current = true;
						setIsAnimating(true);
						const nextSlide = activeSlideRef.current + 1;
						setActiveSlide(nextSlide);
						gotoSectionUp(nextSlide, 1);
					}
				}
			}
		};

		window.addEventListener('keydown', handleKeydown);

		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, [
		activeSlideRef,
		setActiveSlide,
		setProgress,
		tlInfiniteBgArray,
		timelineSlide1,
		timelineSlide2,
		timelineSlide3,
		timelineSlide4,
		timelineSlide5,
		timelineSlide6,
		timelineSlide7,
		timelineSlide8,
		tlInfiniteBg,
		lottieRef,
		animating,
		setIsAnimating,
		setIsChangingSlide,
	]);

	useEffect(() => {
		Observer.create({
			type: 'wheel,touch',
			wheelSpeed: -1,
			onUp: (self) => {
				if (activeSlideRef.current === 0) {
					if (animating.current) return;
					if (!tlInfiniteBgArray.current) return;

					animatingBg.current = true;
					// handleScrollBg(self, 1)
					let factor = 1;
					let direction = 1;
					const scrollVelocity = self.velocityY || 0;
					factor = 1 + Math.abs(scrollVelocity) * 0.0005;
					const slow = gsap.timeline({
						onComplete: () => {
							animatingBg.current = false;
						},
					}).to(tlInfiniteBgArray.current.tlArray, {
						timeScale: factor * direction,
						duration: 0.25,
						onComplete: () => {
							gsap.to(tlInfiniteBgArray.current.tlArray, {
								timeScale: 1,
								duration: 1,
							});
						},
					});

					slow.invalidate();
				}
			},
			onDown: (self) => {
				if (
					activeSlideRef.current === 0 ||
					activeSlideRef.current === 1
				) {
					if (animating.current) return;
					if (!tlInfiniteBgArray.current) return;

					animatingBg.current = true;
					let factor = 1;
					let direction = -1;
					const scrollVelocity = self.velocityY || 0;
					factor = 1 + Math.abs(scrollVelocity) * 0.0005;
					const slow = gsap.timeline({
						onComplete: () => {
							animatingBg.current = false;
						},
					}).to(tlInfiniteBgArray.current.tlArray, {
						timeScale: factor * direction,
						duration: 0.25,
						onComplete: () => {
							gsap.to(tlInfiniteBgArray.current.tlArray, {
								timeScale:
									activeSlideRef.current === 0 ? 1 : 0.1,
								duration: 1,
							});
						},
					});

					slow.invalidate();
				}
			},
			tolerance: 20,
			preventDefault: true,
			dragMinimum: 20,
		});
	}, [activeSlideRef, animatingBg, tlInfiniteBgArray]);
};

export default useObserver;
